import axios from '@/api/axios.js'

export default {
  InitializeInfo(deviceType) {
    return axios.post('InitializeInfoV2', deviceType)
  },
  HotelList() {
    return axios.post('HotelList')
  },
  HotelPageInfo() {
    return axios.post('HotelPageInfo')
  },
  RoomList(hotelId) {
    return axios.post('RoomList', hotelId)
  },
  RestaurantList(hotelId) {
    return axios.post('RestaurantList', hotelId)
  },
  ParkingInfo(hotelId) {
    return axios.post('ParkingInfo', hotelId)
  },
  SeasonPlan() {
    return axios.post('SeasonPlan')
  },
  ActivityList(req) {
    return axios.post('ActivityList', req)
  },
  ActivityInfo(activityId) {
    return axios.post('ActivityInfo', activityId)
  },
  DiscountActivity(activityId, token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`
    return axios.post('DiscountActivity', activityId)
  },
  GetCoupon(req) {
    return axios.post('GetCoupon', req)
  },
}
