import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 600000,
  crossDomain: true,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.error(JSON.stringify(error))
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  response => {
    if (response && response.data.code) {
      switch (response.data.code) {
        case 1000:
        case 1140:
          return Promise.resolve(response.data)
        default:
          return Promise.reject(response.data)
      }
    }
  },
  error => {
    console.error(JSON.stringify(error))
    if (error && error.response) {
      switch (error.response.status) {
        // case 400:
        //   break
        // case 401:
        //   break
        default:
          return Promise.reject(error)
      }
    }
  }
)
export default axiosInstance
