import { createStore } from 'vuex'

export default createStore({
  state: {
    groupInfo: {
      icon: require('@/assets/img/hotel-logo/home-icon.svg'),
      image: '',
      profile: '',
      subTitle: '',
      title: '寒舍集團',
    },
    hotelList: [
      {
        icon: require('@/assets/img/hotel-logo/sheraton.svg'),
        bookingUrl: '',
        calendarUrl: '',
        clubProfile: '',
        id: 'c26ca788-3dab-4478-bb87-ea8bc8e41be8',
        image: '',
        joinClubInfo: '',
        profile: '',
        reservationUrl: '',
        subTitle: '',
        title: '台北喜來登大飯店',
        type: 0,
      },
      {
        icon: require('@/assets/img/hotel-logo/meridien.svg'),
        bookingUrl: '',
        calendarUrl: '',
        clubProfile: '',
        id: 'fe8913bd-af13-4adc-bab2-4bd1941478fc',
        image: '',
        joinClubInfo: '',
        profile: '',
        reservationUrl: '',
        subTitle: '',
        title: '台北寒舍艾美酒店',
        type: 0,
      },
      {
        icon: require('@/assets/img/hotel-logo/humble-house.svg'),
        bookingUrl: '',
        calendarUrl: '',
        clubProfile: '',
        id: '9cbd28ef-b4b7-408d-b8e8-5cf2a065e0e7',
        image: '',
        joinClubInfo: '',
        profile: '',
        reservationUrl: '',
        subTitle: '',
        title: '寒舍艾麗酒店',
        type: 0,
      },
      {
        icon: require('@/assets/img/hotel-logo/mu.svg'),
        bookingUrl: '',
        calendarUrl: '',
        clubProfile: '',
        id: '2f142f4a-703a-4570-b90e-58df3ea2786e',
        image: '',
        joinClubInfo: '',
        profile: '',
        reservationUrl: '',
        subTitle: '',
        title: '礁溪寒沐酒店',
        type: 0,
      },
    ],
    eventList: [],
    selectedRoom: null,
    selectedDiningList: [],
    inleinTokenInfo: '',
    userAuthToken: '',
  },
  mutations: {
    m_setGroupInfo(state, info) {
      state.groupInfo = Object.assign(state.groupInfo, info)
    },
    m_setHotelList(state, hotels) {
      hotels.forEach((hotel, index) => {
        let h = state.hotelList.find(h => h.id == hotel.id)
        if (h) {
          hotels[index] = Object.assign({}, h, hotel)
        }
      })
      state.hotelList = hotels
    },
    m_setEventList(state, events) {
      state.eventList = events
    },
    m_setSelectedRoom(state, room) {
      state.selectedRoom = room
    },
    m_setSelectedDiningList(state, list) {
      state.selectedDiningList = list
    },
    m_setInleinTokenInfo(state, info) {
      state.inleinTokenInfo = info
    },
    m_setUserAuthToken(state, token) {
      state.userAuthToken = token
    },
  },
  actions: {},
  modules: {},
})
