<template>
  <div>
    <router-view />
    <Loading v-if="isLoading" />
    <Overlay v-if="isOverlayShow" />
  </div>
</template>
<script>
import Loading from '@/components/loading.vue'
import Overlay from '@/components/overlay.vue'
import { isLoading } from './helpers/loading.js'
import { isOverlayShow } from './helpers/overlayShow'

export default {
  components: {
    Loading,
    Overlay,
  },
  setup() {
    return {
      isLoading,
      isOverlayShow,
    }
  },
}
</script>
<style lang="scss">
body {
  overflow-x: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
