import { ref } from 'vue'

/**
 * isLoading is a variable that store the state of the showing of loading animation.
 */
export const isLoading = ref(false)

const setLoading = (show = false) => {
  isLoading.value = show
}

/**
 * openLoading shows loading animation up. No parameters needed.
 */
export const openLoading = () => setLoading(true)

/**
 * closeLoading hides loading animation. No parameters needed.
 */
export const closeLoading = () => setLoading()
