/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/store/:sid',
    name: 'Store',
    component: () => import('../views/Store.vue'),
  },
  {
    path: '/room/:sid/:rid',
    name: 'Room',
    component: () => import('../views/Room.vue'),
  },
  {
    path: '/dining/:sid/:did',
    name: 'Dining',
    component: () => import('../views/Dining.vue'),
  },
  {
    path: '/eventList',
    name: 'EventList',
    component: () => import('../views/EventList.vue'),
  },
  {
    path: '/event/:eid',
    name: 'Event',
    props: true,
    component: () => import('../views/Event.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    props: true,
    component: () => import('../views/Privacy.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.backToSave) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(
            savedPosition
              ? savedPosition
              : { left: 0, top: 0, behavior: 'smooth' }
          )
        }, 50)
      })
    } else {
      return { left: 0, top: 0 }
    }
  },
})

export default router
