import { ref } from 'vue'

export const isOverlayShow = ref(false)

const setOverlay = (show = false) => {
  isOverlayShow.value = show
}

export const openOverlay = () => setOverlay(true)

export const closeOverlay = () => setOverlay()
