<template>
  <div class="loading">
    <div class="loading-block ">
      <div class="loading-animation">
        <img
          :src="loadingCircle"
          alt="animation-image"
          class="loading-animation__image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import loadingCircle from '../assets/img/icon/loading.png'
export default {
  name: 'Loading',
  components: {},
  setup() {
    return {
      loadingCircle,
    }
  },
}
</script>

<style lang="scss" scoped>
.loading {
  .loading-block {
    position: fixed;
    width: 96px;
    height: 96px;
    background: rgba(255, 255, 255, 0.92);
    backdrop-filter: blur(100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;

    border-radius: 24px;
    .loading-animation {
      text-align: center;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1055;

      &__image {
        animation: rolling 1200ms infinite;

        @keyframes rolling {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
</style>
