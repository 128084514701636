import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/tailwind.css'
import api from './api/api'
import store from './store'
import './assets/js/webviewBridge'

const app = createApp(App)

app
  .use(router)
  .use(VueAxios, axios)
  .use(store)
app.config.globalProperties.$api = api

init()

async function init() {
  try {
    const res = await api.HotelPageInfo()
    store.commit('m_setGroupInfo', res.data.groupInfo)
    store.commit('m_setHotelList', res.data.hotels)
    app.mount('#app')
  } catch (error) {
    console.error('初始化取得飯店資料失敗', error)
    app.mount('#app')
  }
}

router.afterEach(to => {
  if (typeof gtag == 'function') {
    // eslint-disable-next-line
    gtag('config', process.env.VUE_APP_GA_TRACK_ID, {
      page_path: to.fullPath,
      send_page_view: true,
    })
  }
})
